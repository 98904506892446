.mobile-menu-page {
  .ant-layout-content {
    min-height: calc(100vh - 55px) !important;
    background: #fff9f9;
  }

  .site-layout-background {
    background: #fff9f9 !important;
  }

  .ant-layout-footer {
    display: none;
    position: fixed;
  }

  .menu-box {
    background: #fff9f9;
    padding: 10px;

    h2 {
      padding: 20px 0;
      text-align: center;
    }

    .tagline {
      background: #fff;
      text-align: center;
      margin: 30px 10px;
    }

    .menu-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      a {
        display: flex;
        flex: 50%;

        .item {
          padding: 10px;
          width: 100%;

          .item-content {
            display: flex;
            border-radius: 12px;
            width: 100%;
            height: 160px;
            box-shadow: 0 0 10px rgba(#000, 0.1);
            flex-direction: column;
            background: #fff;
            justify-content: center;
            align-items: center;

            h3 {
              height: 18px;
              margin-top: 10px;
              font-size: 16px;
              line-height: 1;
              text-align: center;
              padding: 0 20px;
            }
          }

          &.mobile-survey {
            .item-content {
              background: #d36500;
            }
          }

          &.follow-up {
            .item-content {
              background: #79bc44;
            }
          }

          &.data-analytics {
            .item-content {
              background: #ffc300;
            }
          }

          &.dashboard {
            .item-content {
              background: #d73343;
            }
          }
        }
      }
    }
  }
}
