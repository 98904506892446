.dashboard-chart-container {
  display:flex;
  padding: 10px;
  margin:10px;
  background-color: black;
  border-radius: 5px;

}

.dashboard-chart-container div, .dashboard-chart-container div h5 , .dashboard-chart-container div p {
  background-color: black;
}

.dashboard-chart-container div {
  padding: 10px;
  flex: 1 0 100%;
}

.chartarea {  background-color: black; margin: 5px; border: 1px var(--bs-gray-800) solid;}

.rv-xy-plot__inner {
  background-color: unset;}

input.analytics  {
  background-color: white;
  color: black;
  margin-left: 10px !important;
  border: none;
}

table.summary tr td {text-align: left;}
