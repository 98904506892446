
:root {
  --bs-lightgrey: #20c997;
}

body {
    background-color: #282c34 !important;
}

small {color: inherit;font-size:0.6rem!important;}

.login {
  width: 50vw !important;
}

.login Button {
  width: unset !important;
  height: unset !important;
}
.sidenav {background-color: var(--bs-gray-800); }
.sidenav div {background-color: inherit;color: var(--bs-gray-500);}
.sidenav .col-sm-9 {text-align: left;}
.sidenav svg path {color: var(--bs-gray-500);}

.sidenav .active  path {color: white ;}
.top-bar h2{
text-align: left;
background-color: var(--bs-gray-700);
padding: 10px;
    /*  display:flex; */
}

.top-bar .person {

  float:right;
  position: relative;
  top:-50px;
  right: 20px;
  background-color: var(--bs-gray-700);
  display: flex;
}

.person div {background-color: var(--bs-gray-700);}
.event-select-container, .side-and-content {
  display:flex;
}
.event-select-container >div {
  padding: 10px;
  text-align: left;
}

.crm-container {
  text-align: center;
  background-color: var(--bs-gray-900);
  color:white;
  width: 99vw;

}

.crm-container .container  {
  max-width: inherit;
}
.select-container {
display:inline-flex;
}



td , th {
   min-width:70px;
   padding-left: 5px;
   padding-right:5px;

}

th {color:  var(--bs-red);}
.td-text-align-right {
  text-align: right;
}

/*
tbody tr:nth-child(odd) td, tbody tr:nth-child(odd) td .select-container {
  background-color: var(--bs-pink) !important;
  color: #fff;
}
*/


 select {font-size: 1rem !important;}

 .ant-select {
    min-width: 100%;
 }

.follow-up-ic-select {
  width: 150px;
}
 .event-content-box , .events-container {display:flex;}


.events-container > div {
display:flex;
  width: calc(100vw - 350px);}


.event-content-box   div {padding:5px 5px 5px 0;}
.event-content-box   .name-list {padding: 10px;}

/* .add-case-container {  background-color: var(--bs-red);} */
.add-case-container .btn.btn-primary, .event-content-box .btn.btn-primary {
  width: unset;
  height: 50px;
  background-color: var(--bs-red) !important;
  font-weight: bold;
}
.add-case-container .btn.btn-primary svg {
  position: relative !important;
  left: 0px;
  top: -0px;
}

.action-note {

padding:5px;
  width:200px;
  border-radius:5px;
    /* background-color: #FF8080 !important; */
    text-align: left;
}
.action-note div, .action-note div span {
  background-color: inherit;
  color: white;
  font-size:0.8rem;
  padding:0px;
  margin: 0px;

}
.action-note , .action p {

color: black;
}
.action-note select {
  width: 100%;
  font-size: 1rem;
}


.td-action-note {
  position: absolute;
  /* width:400px; */
  padding: 10px;

}





.action-note {
  background-color: var(--bs-gray-800);
  position:relative;

  width:400px;
/*  right:150px;*/
}

.action-note .note {
  background-color: var(--bs-gray-600);
  color: white;
  width:100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 5px;;
}

.action-note .note h6 {
    padding: 5px;
    background-color: var(--bs-gray-500);
      color: black;
      font-size: 0.9rem !important;
      display: flex;
      width:100%
}
.action-note .note h6 .type {
  width:50%;
    background-color: inherit;
    color: inherit;
}
.action-note .note input {
  width: 100%;
}


.action-note .note h6 .date {
  width:50%;
  text-align: right;
    background-color: inherit;
    color: inherit;
font-size: 0.6rem;
}

.action-note textarea::-webkit-input-placeholder, .action-note input::-webkit-input-placeholder {
  color: var(--bs-blue) ;
  font-weight: bolder;
}
.action-note .note .btn.btn-primary {
  width: unset;
  height: unset;
  background-color: var(--bs-red) !important;
  font-weight: bold;
  font-size:0.8rem;
}

.btn.btn-primary {
  background-color: var(--bs-gray-800) !important;
  border: 0px;
  height: 25px;
  width: 25px;
  margin-right: 5px;

}

.btn.btn-primary svg {
  position: relative;
  left: -7px;
  top: -5px;
}
/*
.action-note {
  background-color: black;
  color: white;
  padding: 5px;

}

*/


.ant-table-content {

  overflow:visible !important;
}


@media(max-width: 600px){
  .login {
    width: 100vw !important;
  }

  .top-bar h2{
    font-size: 0.8rem;
  }

  .top-bar .person {
    top: -38px;
  }
}



@media(max-width: 1200px){
.sidenav {width:60px;}
.sidenav .col-sm-9 {font-size: 0rem;}


}
