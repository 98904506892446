@import "~antd/es/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "./config.less";
@import "./mixins/responsive.less";

.note-cell {
  display: flex;
  width: 100%;
  max-width: 100%;
  border: 1px solid #e2dddd;
  border-radius: 5px;
  background: #fff;
  align-items: center;
  position: relative;
  user-select: none;

  .text {
    flex: 1;
    text-align: left;
    padding: 0 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-btn {
    border: none;
  }

  .note-cell-dropdown-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  .note-cell-dropdown {
    position: absolute;
    top: -1px;
    right: -2px;
    min-width: 220px;
    max-width: 320px;
    max-height: 480px;
    text-align: left;
    border: 1px solid #e2dddd;
    border-radius: 5px;
    background: #fff;
    z-index: 11;
    box-shadow: 0 0 10px rgba(#000, 0.12);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #eee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }

    &.simple {
      p {
        border: 1px solid #e2dddd;
        border-radius: 3px;
        padding: 10px;
        margin: 0;
      }
    }

    .note-cell-dropdown-head {
      text-align: right;
      display: flex;
    }

    .note-cell-dropdown-content {
      padding: 0 10px 10px 10px;
    }

    .form-content {
      border: 1px solid #e2dddd;
      border-radius: 5px;

      + .form-content {
        margin-top: 10px;
      }

      .form-content-header {
        background: #ffeded;
        padding: 10px;

        h3 {
          color: @color-primary;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          padding: 0;
          line-height: 1;
        }

        p {
          font-size: 11px;
          margin: 0;
          padding: 0;
          line-height: 1;

          span {
            color: #999;
          }
        }
      }

      .ant-form {
        min-width: 280px;
        padding: 10px;

        .ant-form-item {
          margin-bottom: 10px;

          .ant-form-item {
            margin-bottom: 0 !important;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }

          &.actions {
            text-align: right;
          }
        }
      }
    }
  }
}

.filter-section {
  background: #fafafa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  .filter-box-wrapper {
    width: fit-content;
    .slider-range-value {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px 20px 10px;
      font-size: 13px;
      color: #999;
    }
  }

  .filter-box {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 0 12px rgba(#000, 0.12);
    border-radius: 8px;
    text-align: left;

    h4 {
      padding: 0 10px;
    }

    &.radio {
      .ant-radio-button-wrapper {
        margin: 5px;
        min-width: 60px;
        text-align: center;
        border-radius: 2px !important;
      }
    }

    + .filter-box {
      margin-top: 20px;
    }
  }
}

.ant-table-thead {
  .ant-table-cell {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
  }
}

.ant-select-selection-placeholder {
  color: black;
}
.ant-select-dropdown {
  .ant-select-item-option {
    border-radius: 5px;

    &.ant-select-item-option-active {
      background: @color-primary;
      color: #fff;
    }

    &.ant-select-item-option-disabled {
      background: #f0f0f0;
    }
  }

  .rc-virtual-list {
    padding: 10px;
  }
}

.main-menu-drawer {
  display: none;
  .ant-drawer-body {
    padding: 0;
    .ant-menu-submenu {
      svg {
        margin-right: 10px;
      }
    }
    .ant-menu-item {
      padding-top: 20px;
      padding-bottom: 20px;
      height: 60px;
      svg {
        margin-right: 10px;
      }
      &.ant-menu-item-selected {
        background: @color-primary;
        color: #fff;

        svg {
          * {
            fill: #fff;
            color: #fff;
          }
        }
      }
    }
  }
  .media-breakpoint-down(md;
  {
    display: block !important;
  });
}

// REPOSONSIVE
.media-breakpoint-down(md;
  {
    .default.layout {
      .ant-layout {
        .ant-layout-sider {
          display: none;
        }

        .ant-layout-content {
          padding: 0 !important;

          .site-layout-background {
            padding: 0 !important;

          }
        }
      }
      .ant-layout-header {

        padding-right: 5px !important;
        h1 {
          display: block !important;
        }
        .username {
          display: none;
        }
      }

      .ant-table-wrapper { padding-bottom: 30px !important;}
    }


    .overview-page {
      .ant-page-header {
        margin-bottom: 0 !important;
      }
      .filter-box {
        .ant-row {
          flex-direction: column;
        }

        .support-level-explain {
          padding: 0;
          border: 0;

          .statistic-box-wrapper {
            width: 100% !important;
          }
        }
      }
    }
}
);
