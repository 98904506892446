.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    display: flex;
    list-style-type: none;

    li.previous {
      a {color: white;}
    }

    li.next {
      a {color: white;}
    }

    li.selected {
      color: white;
      background-color: var(--bs-red) ;
      width:50px;
      border-radius: 25px;
      font-weight:bolder;
    }
    li {
      padding: 10px;
    }
  }
}
