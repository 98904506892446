@import "layouts/mixins/responsive.less";
@import "layouts/config.less";

.overview-page {
  .filter-box {
    padding: 20px;

  }

  .support-level-chart {
    height: 450px;

  }

  .support-level-explain {
    padding: 20px 20px 0 40px;
    border-left: 1px solid #ececec;
    height: 100%;

    .support-level-explain-box {
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      overflow: hidden;


      .support-level-explain-box-header {
        padding: 5px 10px;
        text-align: center;
        background: #f9f9f9;
        border-bottom: 1px solid #e0e0e0;
        font-size: 22px;
      }

      .support-level-explain-box-content {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;

        .statistic-box-wrapper {
          width: 50%;
          padding: 10px;
          box-sizing: border-box;
        }

        .statistic-box {
          text-align: center;
          border-width: 1px;
          border-style: solid;

          h4 {
            font-size: 17px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              display: inline-block;
              width: 17px;
              height: 17px;

              border-radius: 3px;
              margin-right: 10px;
              content: "";
            }
          }

          h3 {
            font-size: 26px;
          }

          &.yellow {
            border-color: #f6eac5;
            background: #fffaeb;

            h4 {
              &::before {
                background: #ffc300;
              }

              span {
                color: #e3b10d;
              }
            }
          }

          &.green {
            border-color: #e0fccc;
            background: #f5fcf0;

            h4 {
              &::before {
                background: #79bc44;
              }

              span {
                color: #79bc44;
              }
            }
          }

          &.red {
            border-color: #ffd8db;
            background: #fff2f3;

            h4 {
              &::before {
                background: #d73343;
              }

              span {
                color: #d73343;
              }
            }
          }

          &.na {
            border-color: #c1f3e9;
            background: #e6fffa;

            h4 {
              &::before {
                background: #878484;
              }

              span {
                color: #878484;
              }
            }
          }
        }
      }
    }
  }

  .ant-page-header {

    .ant-select-selection-item {
      width: 150px;
    }
    .media-breakpoint-down(md;

        {
        .ant-page-header-heading-extra {
          width: 100%;

          .ant-space {
            width: 100% !important
          }

          .ant-form-inline {


            flex-direction: column;



            .ant-form-item {
              width: 100%;
              margin-right: 0;
              margin-top: 20px;



              .ant-form-item-label {
                margin-bottom: 0;

                label {
                  height: auto;
                }
              }

              .ant-picker {
                width: 100%;
              }
            }
          }
        }
      });

  }

  .ant-page-header-heading {
    justify-content: flex-start;
  }
}
