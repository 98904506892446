@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,500&display=swap");
@import "./config.less";
@import "./mixins/responsive.less";

.layout {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.auth.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url("./media/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-box {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 50px 35px 50px;
      box-shadow: 0 0 12px rgba(#000, 0.12);
      border-radius: 15px;

      img.logo {
        margin-bottom: 30px;
      }

      .title {
        font-size: 24px;
        line-height: 1;
        margin: 0;
      }

      .tagline {
        font-size: 14px;
        color: @color-primary;
        background: @color-primary;
        height: 4px;
        width: 80%;
        margin: 20px 0 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          background: #fff;
          padding: 0 10px
        }
      }

      .ant-form {
        width: 320px;

        .term-checkbox {
          font-size: 10px;
          line-height: 12px;
        }

        .form-actions {
          text-align: center;

          .ant-btn-primary {
            min-width: 150px;
          }
        }
      }
    }

    .menu-box {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 50px 35px 50px;
      box-shadow: 0 0 12px rgba(#000, 0.12);
      border-radius: 15px;
      width: 888px;

      .title {
        font-size: 32px;
        line-height: 1;
        margin: 0;
      }

      .tagline {
        font-size: 24px;
        line-height: 1;
        margin: 0;
      }

      .menu-items {
        width: 434px;
        height: 434px;
        border-radius: 434px;
        overflow: hidden;
        margin: 60px 0;
        box-shadow: 0 4px 4px rgba(#000, 0.25);

        .item {
          width: 50%;
          height: 217px;
          display: inline-block;


          h3 {
            color: #fff;
            line-height: 1;
            height: 0;
            margin-top: 10px;
          }

          .item-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            width: 80%;
            height: 160px;
          }

          &.mobile-survey {
            background: #D36500;

            .item-content {
              margin: 40px 0 0 40px;
            }


          }

          &.follow-up {
            background: #79BC44;

            .item-content {
              margin: 40px 0 0 20px;
            }

          }

          &.data-analytics {
            background: #FFC300;

            .item-content {
              margin: 0 0 0 40px;
            }

          }

          &.dashboard {
            background: #D73343;

            .item-content {
              margin: 0 0 0 10px;
            }


          }
        }
      }


    }
  }

  footer {
    text-align: center;
    background: @color-primary;
    color: #fff;
    padding: 10px;
  }
}