.main-event-page {
    height: 100vh;

    .menu-box {
      width: 100vw;
      text-align: center;
        a {
           
       
            text-decoration: none;
        
      
        .option {
      
           margin-left: 10px;
           margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 5px;;
     padding: 50px;
    background-color: red;

    h2 {
        color: white;
    }
        }
    }
}
}