.survey-report-page {
  .filter-section {
    .filter-box {
      &.slider {
        padding: 10px;
        width: 350px;
      }
      &.radio {
        padding: 10px;
      }
    }
  }
}
