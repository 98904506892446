@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,500&display=swap");
@import "./config.less";
@import "./mixins/responsive.less";

.layout {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.default.layout {
  .ant-layout {
    min-height: 100vh;
    .media-breakpoint-down(md;

        {
        min-height: 0 !important;
      });

    .ant-layout-header {
      background: @color-primary;
      height: 55px;
      padding: 0;
      line-height: 1;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;

      h1 {
        display: none;
        text-align: center;
        color: #fff;
        line-height: 1;
        margin-bottom: 0;
      }

      .logo-icon {
        flex: 1;
        text-align: center;
      }

      .user {
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;

        .avatar {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        .username {
          margin: 0 10px;
        }
      }
    }

    .ant-layout-sider {
      background: #fff9f9;
      box-shadow: 2px 2px 4px rgba(#000, 10%);

      // LOGO
      .logo {
        padding: 20px;

        span {
          font-weight: bold;
          display: inline-block;
          padding-left: 10px;
        }
      }

      // MENU
      .ant-menu {
        background: none !important;
        padding: 10px;

        .ant-menu-item {
          font-weight: bold;
          height: 50px;
          border-radius: 6px;
          padding: 0 10px !important;

          span {
            padding: 0 16px;
          }
        }

        .ant-menu-item-selected {
          background-color: @color-primary;
          color: #fff;

          svg * {
            fill: #fff;
          }
        }

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding-left: 10px !important;
          }

          .ant-menu-title-content {
            // width: 100px;
            padding: 0 16px;
            color: #000;
            font-weight: bold;
          }
        }
      }

      // COLLAPSED STATE
      &.ant-layout-sider-collapsed {
        .logo {
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            display: none;
          }
        }

        .ant-menu {
          padding: 0;

          .ant-menu-item {
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: auto;
            padding: 5px 0 !important;

            span {
              text-align: center;
              display: inline;
              width: 100%;
              word-wrap: break-word;
              white-space: pre-line;
              padding: 5px !important;
              line-height: 1.2;
            }
          }

          .ant-menu-submenu {
            padding: 0;

            .ant-menu-submenu-title {
              padding-left: 10px !important;
              display: flex;
              flex-direction: column;
              height: 80px;
              width: 90px;
              padding: 0;
              margin: 0;

              justify-content: center;
              align-items: center;
              left: -5px;
            }

            .ant-menu-title-content {
              box-sizing: border-box;
              width: 90px;
              padding: 0 16px;
              margin: 0;
              color: #000;
              font-weight: bold;
              word-wrap: break-word;
              white-space: pre-line;
              line-height: 1.2;
              text-align: center;
            }
          }
        }
      }
    }

    .ant-layout-content {
      padding: 10px;
      margin: 0 !important;

      .site-layout-background {
        padding: 20px !important;
        background: #fff;
        height: calc(100% - 70px);
        .media-breakpoint-down(md;

            {
            padding: 0 !important;
          });
      }

      .ant-page-header {
        padding: 0;
        margin-bottom: 20px;
        .media-breakpoint-down(md;

            {
            padding: 20px;
          });

        .ant-page-header-heading-left {
          flex-direction: column;
          align-items: flex-start;
        }

        .ant-page-header-heading-title {
          font-size: 26px;
        }

        .ant-page-header-heading-extra {
          .ant-btn {
            background: #fff9f9;
            border-width: 2px;
            border-color: #f1c9c9;
            border-radius: 6px;
            height: 40px;
            font-size: 14px;

            .anticon {
              color: @color-primary;
            }
          }

          .ant-btn.ant-btn-primary {
            background: @color-primary;
            border-color: @color-primary;

            .anticon {
              fill: #fff;
              color: #fff;
            }
          }

          .media-breakpoint-down(md;

              {
              .ant-space {
                flex-wrap: wrap;

                .ant-space-item {
                  flex: 1;

                  .ant-btn {
                    width: 100%;
                  }
                }

              }
            });
        }
      }
    }

    .ant-layout-footer {
      text-align: center;
      background: #fff;
    }
  }

  .ant-table-content {
    .media-breakpoint-up(lg;

        {
        // overflow: visible !important;
      });
  }
}

// REPOSONSIVE
// .media-breakpoint-down(md;
//   {
//     .default.layout {
//       .ant-layout {
//         .ant-layout-sider {

//         }
//       }
//     }
// }
// );
