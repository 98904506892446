
.range-container {
  padding: 20px;
  width: 500px;
}
.multi-range-slider {
  /* width: 60vw; */
  padding: 20px;
  box-shadow: None;
}
.range-display .l {float:left; font-size: 0.8rem;}
.range-display .r {float: right;font-size: 0.8rem;}
.blocks-container {
  display:flex;
   flex-wrap: wrap;
     padding: 0 0 0 15px;
}
.blocks-container .blocks  {flex: 0 0 5%;}

.blocks input {
  font-size: 0.8rem;
  padding: 0 5px 0 0;
  width: 40px;
  border:none;
}
.bar-right, .bar-left {
  box-shadow: unset !important;
}

.thumb::before {
    box-shadow: unset !important;
    background-color:  var(--bs-red)  !important;
    border: unset !important;
}
.bar-inner {
    border: unset !important;
}
.bar-inner-left ,.bar-inner-right  {
  background-color:  var(--bs-red);

}

.blocks .btn.btn-primary {
  height: unset;
  width: unset;
  margin-bottom: 5px;
}
.blocks input {
  background-color: var(--bs-gray-800);
}

td , th {
   min-width:unset;

/*font-size: 0.8rem; */
}

td.s , th.s {

 width: 5px;
  min-width: unset;
}
